<template>
  <div class="ApproveArticleDetail">
    <div class="title">基本信息</div>

    <div class="oneTitle">
      <div class="particulars">
        <div class="particularsOne">申请单号：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">申请人：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">申请医院：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">申请总量：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">申请状态：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">申请时间：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">申请原因：</div>
        <div class="particularsTwo"></div>
      </div>
    </div>

    <div class="title">申请明细</div>
    <div class="tableBox">
      <el-table :data="tableData" stripe v-loading="loading">
        <el-table-column
          type="index"
          label="序号"
          height="30"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop=""
          label="物品类型"
          height="30"
          align="center"
        ></el-table-column>
   
        <el-table-column
          prop=""
          label="物品名称"
          height="30"
          align="center"
        ></el-table-column>
        <el-table-column
          prop=""
          label="存货"
          height="30"
          align="center"
        ></el-table-column>
        <el-table-column
          prop=""
          label="数量"
          height="30"
          align="center"
        ></el-table-column>
      </el-table>
    </div>

    <div class="title">物流信息</div>
    <div class="oneTitle">
      <div class="particulars">
        <div class="particularsOne">快递类型：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">快递公司：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">快递单号：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">收货人：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">电话：</div>
        <div class="particularsTwo"></div>
      </div>
      <div class="particulars">
        <div class="particularsOne">收获地址：</div>
        <div class="particularsTwo"></div>
      </div>
    </div>

    <div class="buttonBox">
      <el-button type="primary" @click="doSomeThing(0)">打印</el-button>
      <el-button type="primary" @click="doSomeThing(1)">驳回</el-button>
      <el-button type="primary" @click="doSomeThing(2)">同意</el-button>
      <el-button type="primary" @click="doSomeThing(3)">发货</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detailData: {},
      loading: false,
      tableData: [{ input: '' }],
    }
  },
  methods: {
    doSomeThing(value) {
      if (value == 1) {
        // this.$message.error('错了哦，这是一条错误消息')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ApproveArticleDetail {
  margin: px(20);
  .title {
    font-size: px(20);
    font-weight: bold;
    color: #111111;
    margin-bottom: px(20);
  }
  .oneTitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #fff;
    border-radius: px(5);
    margin-bottom: px(20);
    .particulars {
      border-right: 1px solid #ebf0f6;
      width: 23.8%;
      height: px(68);
      padding: px(25) 0 0 px(15);
      font-size: px(18);
      .particularsOne {
        color: #999999;
        margin-bottom: px(8);
      }
      .particularsTwo {
        color: #333333;
        font-weight: bold;
      }
    }
    .particulars:nth-child(1),
    .particulars:nth-child(2),
    .particulars:nth-child(3),
    .particulars:nth-child(4) {
      border-bottom: 1px solid #ebf0f6;
    }
    .particulars:nth-child(4) {
      border-right: none;
    }
  }

  .tableBox {
    background: #fff;
    border-radius: px(5);
    padding: px(20);
    margin-bottom: px(20);
  }
  .buttonBox {
    margin-top: px(20);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>